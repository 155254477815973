<template>
  <div class="agreement">
    <LoadingSpinner :isLoading="isLoading" />
    <div class="flex flex-col">
      <div class="w-full bg-action-bar px-2 py-2 clearfix">
        <div class="float-right flex flex-wrap">
          <div>
            <button class="btn-blue-outline mx-2 mt-3 sm:mt-0" @click.prevent="exportXLS()">
              {{ $t("export") }}
              <BaseIcon icon="file-excel" class="ml-1" />
            </button>
            <router-link v-if="user.role == 'admin' || user.role == 'seller'" class="btn-green" to="/admin/skapa-avtal">
              {{ $t("create_new_agreement") }}
              <BaseIcon icon="plus" class="ml-2" />
            </router-link>
          </div>
        </div>
      </div>
      <div class="w-full mt-6">
        <vue-good-table
          styleClass="vgt-table"
          row-style-class="text-sm"
          :columns="translatedColumns"
          :rows="agreements"
          :search-options="{
            enabled: false,
          }"
          :pagination-options="{
            enabled: false,
          }"
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'agreement_number'" @click.prevent="showAgreementAlarm(props.row.agreement_number)" class="link">
              {{ props.row.agreement_number }}
            </span>
            <span v-else-if="props.column.field == 'alarm_count' && (user.role == 'admin' || user.role == 'seller')">
              {{ getAlarmCount(props.row.agreement_number) }}
            </span>
            <span v-else-if="props.column.field == 'invoice' && user.role == 'admin'">
              <button v-if="props.row.invoice_date == null" class="btn-blue-outline" @click.prevent="sendInvoiceEmailPrompt(props.row)">
                <BaseIcon icon="file-invoice-dollar" />
              </button>
              <span class="text-xs" v-else>{{ formatDate(props.row.invoice_date) }}</span>
            </span>
            <span v-else-if="props.column.field == 'action'">
              <a v-if="props.row.pdf_file != null" :href="props.row.pdf_file" download>
                <BaseIcon icon="file-pdf" class="text-red-600 text-xl cursor-pointer" />
              </a>
            </span>
            <span v-else-if="props.column.field == 'edit' && (user.role == 'admin' || user.role == 'seller')">
              <button class="btn-secondary-outline" @click.prevent="showEdit(props.row)">
                <BaseIcon icon="pencil-alt" />
              </button>
            </span>
            <span v-else-if="props.column.field == 'delete' && user.role == 'admin'">
              <button class="btn-red-outline" @click.prevent="deleteAgreementPrompt(props.row)">
                <BaseIcon icon="trash" />
              </button>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
          <div slot="emptystate" class="flex flex-wrap flex-col items-center my-5 text-sm">
            {{ $t("my_agreement_table.no_agreement_found") }}
          </div>
        </vue-good-table>
      </div>
    </div>

    <AgreementUpdate :agreementInfo="updateInfo" @updateAgreement="getAgreements()" />
    <AgreementAlarm :agreementNumber="selectedAgreementNumber" />
  </div>
</template>

<script>
import AgreementUpdate from "@/components/modal/agreement_update";
import AgreementAlarm from "@/components/modal/agreement_alarm";

export default {
  title() {
    return this.$t("page_titles.agreement");
  },
  components: {
    AgreementUpdate,
    AgreementAlarm,
  },
  data() {
    return {
      id: this.$route.params.id,
      type: this.$route.params.type,
      isLoading: false,
      agreements: [],
      alarm_centers: [],
      agreement_alarms: null,
      sellers: null,
      updateInfo: null,
      selectedAgreementNumber: null,
    };
  },

  computed: {
    user() {
      return this.$store.state.user;
    },
    translatedColumns() {
      return [
        { label: this.$t("my_agreement_table.agreement_number"), field: "agreement_number", sortable: true },
        { label: this.$t("my_agreement_table.contract_period"), field: "contract_period", sortable: false },
        { label: this.$t("my_agreement_table.name"), field: "customer_name", sortable: false },
        { label: this.$t("my_agreement_table.contact_person"), field: "customer_contact", sortable: false },
        { label: this.$t("my_agreement_table.is_reference"), field: "customer_reference", sortable: false },
        { label: this.$t("my_agreement_table.seller"), field: "seller", sortable: false, formatFn: this.getSellerName },
        { label: "", field: "invoice", sortable: false },
        { label: "", field: "alarm_count", sortable: false },
        { label: "", field: "action", sortable: false },
        { label: "", field: "edit", sortable: false },
        { label: "", field: "delete", sortable: false },
      ];
    },
  },

  methods: {
    getAgreements() {
      let id = this.id;
      if ((this.user.role == "superuser" || this.user.role == "user") && id == "-") id = "";

      let type = this.type;
      if (this.user.role == "superuser") type = "customer";
      else if (this.user.role == "user") type = "unit";
      else if (this.user.role == "reseller") type = "reseller";
      else if (this.user.role == "seller") type = "seller";

      let apiUrl = `${process.env.VUE_APP_SERVER_URL}/agreement/${type}/${this.id}`;

      if (this.$route.name == "agreements" && this.user.role == "admin") apiUrl = `${process.env.VUE_APP_SERVER_URL}/agreements/`;
      else if (this.type != "customer" && this.type != "unit" && this.type != "reseller" && this.type != "seller") return;

      this.axios
        .get(apiUrl)
        .then((response) => {
          this.agreements = response.data.agreements;
          if (this.user.role == "admin" || this.user.role == "seller") this.agreement_alarms = response.data.agreement_alarms;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    getAlarmCenters() {
      this.axios
        .get(`${process.env.VUE_APP_SERVER_URL}/alarm-central`)
        .then((response) => {
          this.alarm_centers = response.data.data;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    getSellerReseller() {
      this.axios
        .get(`${process.env.VUE_APP_SERVER_URL}/seller/reseller`)
        .then((response) => {
          this.sellers = response.data.data;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    sendInvoiceEmailPrompt(agreement) {
      this.$modal.show("dialog", {
        title: `<span class="text-sm font-semibold">Är du säker på att du vill skicka detta? <b>${agreement.agreement_number}</b></span>`,
        buttons: [
          {
            title: '<div class="bg-secondary-500 text-white text-sm font-sans py-2">NEJ</div>',
            handler: () => {
              this.$modal.hide("dialog");
            },
          },
          {
            title: '<div class="bg-red-500 text-white text-sm font-sans py-2">JA</div>',
            handler: () => {
              this.sendInvoiceEmail(agreement);
            },
          },
        ],
      });
    },

    async sendInvoiceEmail(agreement) {
      try {
        this.isLoading = true;

        let headers = {
          "Content-Type": "multipart/form-data",
          "X-Access-Token": this.$store.state.token,
        };

        let pdfFile = null;

        if (agreement.pdf_file == null) {
          let doc = this.generateAgreementPDF(agreement);
          pdfFile = doc.output("blob");
        } else {
          let res = await fetch(agreement.pdf_file);

          if (res.ok) {
            pdfFile = await res.blob();
          } else {
            this.handleError(null, "Det gick inte att få avtal pdf");
            this.isLoading = false;
            return;
          }
        }

        let formData = new FormData();
        formData.append("pdf", pdfFile);

        // eslint-disable-next-line
        let response = await this.axios.put(`${process.env.VUE_APP_SERVER_URL}/agreement/invoice/${agreement._id}`, formData, headers);

        this.getAgreements();
        this.$modal.hide("dialog");
        this.isLoading = false;
        this.handleSuccess("Fakturamail skickat");
      } catch (error) {
        this.handleError(error);
        this.$modal.hide("dialog");
        this.isLoading = false;
      }
    },

    exportXLS() {
      let export_columns = [];

      this.agreements.forEach((agreement) => {
        export_columns.push({
          [this.$t("export_agreement.agreement_number")]: agreement.agreement_number,
          [this.$t("export_agreement.contract_period")]: agreement.contract_period,
          [this.$t("export_agreement.name")]: agreement.customer_name,
          [this.$t("export_agreement.contact_person")]: agreement.customer_contact,
          [this.$t("export_agreement.your_reference")]: agreement.customer_reference,
          [this.$t("export_agreement.seller")]: this.getSellerName(agreement.seller),
        });
      });

      this.excelExport(export_columns, "Avtal", "avtal_rapport");
    },

    deleteAgreementPrompt(agreement) {
      this.$modal.show("dialog", {
        title: `<span class="text-red-500">Radera avtal ${agreement.agreement_number}?</span>`,
        buttons: [
          {
            title: `<div class="bg-accent-500 text-white text-sm font-sans py-2">${this.$t("user_actions.cancel")}</div>`,
            handler: () => {
              this.$modal.hide("dialog");
            },
          },
          {
            title: `<div class="bg-red-500 text-white text-sm font-sans py-2">${this.$t("user_actions.delete")}</div>`,
            handler: () => {
              this.deleteAgreement(agreement._id);
            },
          },
        ],
      });
    },

    async deleteAgreement(_id) {
      try {
        // eslint-disable-next-line
        let response = await this.axios.delete(`${process.env.VUE_APP_SERVER_URL}/agreements/${_id}`);

        this.$modal.hide("dialog");
        this.handleSuccess("Avtalet har tagits bort");

        this.getAgreements();
      } catch (error) {
        this.$modal.hide("dialog");
        this.handleError(error);
      }
    },

    getSellerName(id) {
      let seller = _.find(this.sellers, { _id: id });
      if (seller) return seller.name;
      else return "";
    },

    getAlarmCount(agreement_number) {
      let alarm = _.filter(this.agreement_alarms, { agreement_number: agreement_number });
      if (alarm) return alarm.length;
      else return 0;
    },

    formatDate(date) {
      return this.moment(date).format("YYYY-MM-DD");
    },

    showEdit(agreement) {
      this.updateInfo = _.cloneDeep(agreement);
      this.$modal.show("update-agreement");
    },

    showAgreementAlarm(agreementNumber) {
      this.selectedAgreementNumber = agreementNumber;
      this.$modal.show("agreement-alarm");
    },
  },

  created() {
    this.getAgreements();
    if (this.user.role == "admin" || this.user.role == "seller") this.getAlarmCenters();
    if (this.user.role != "tech") this.getSellerReseller();

    if (this.user.role == "admin") this.setPageTitle(this.$t("top_titles.agreement"), "agreements");
    else this.setPageTitle(this.$t("top_titles.agreement"), "customer_agreements");
  },
  watch: {
    "$i18n.locale"() {
      document.title = this.$t("page_titles.agreement");
      this.setPageTitle(this.$t("top_titles.agreement"), "customer_agreements");
    },
  },
};
</script>
