<template>
  <modal name="update-agreement" class="modal-inner modal-update-agreement" transition="pop-out" :width="modalWidth" :focus-trap="true" :min-height="600" height="auto" :scrollable="true">
    <span class="close-button" @click="hide('update-agreement')"><BaseIcon icon="times-circle" class="text-white"/></span>
    <div class="modal-header text-lg bg-primary-50 font-serif">
      <span v-if="updateInfo">{{ updateInfo.agreement_number }}</span>
    </div>
    <div class="modal-body">
      <ValidationObserver ref="formUpdateAgreement" v-if="updateInfo != null">
        <div class="flex flex-wrap">
          <div class="w-full md:w-1/3 px-2 py-2">
            <BaseSelect v-model="updateInfo.agreement_type" :options="agreement_types" nameSelector="name" optionSelector="id" field_name="Avtalstyp" disabled readonly />
          </div>
          <div class="w-full md:w-1/3 px-2 py-2">
            <BaseInput v-model="updateInfo.agreement_number" type="text" field_name="Avtalsnummer" disabled readonly />
          </div>
          <div class="w-full md:w-1/3 px-2 py-2">
            <BaseInput v-model="updateInfo.e_bill" type="text" field_name="E-faktura" />
          </div>
        </div>

        <div class="mx-2 border-b border-secondary-400 my-5"></div>
        <div class="flex flex-wrap">
          <div class="w-full text-sm font-bold italic px-2 mb-4">
            {{ $t("agreement_update_modal.lessee") }}
          </div>
        </div>
        <div class="flex flex-wrap">
          <div class="w-full md:w-1/3 px-2 py-2">
            <BaseInput v-model="updateInfo.customer_name" type="text" field_name="Namn" rules="required" />
          </div>
          <div class="w-full md:w-1/3 px-2 py-2">
            <BaseInput v-model="updateInfo.company_number" type="text" field_name="Org. nummer" rules="required" />
          </div>
          <div class="w-full md:w-1/3 px-2 py-2">
            <BaseInput v-model="updateInfo.customer_phone" type="text" field_name="Telefon" rules="required" />
          </div>
        </div>
        <div class="flex flex-wrap">
          <div class="w-full md:w-1/3 px-2 py-2">
            <BaseInput v-model="updateInfo.billing_adress" type="text" field_name="Fakturaadress" rules="required" />
          </div>
          <div class="w-full md:w-1/3 px-2 py-2">
            <BaseInput v-model="updateInfo.billing_zip_code" type="text" field_name="Postnummer" rules="required" />
          </div>
          <div class="w-full md:w-1/3 px-2 py-2">
            <BaseInput v-model="updateInfo.billing_city" type="text" field_name="Ort" rules="required" />
          </div>
        </div>
        <div class="flex flex-wrap">
          <div class="w-full md:w-1/3 px-2 py-2">
            <BaseInput v-model="updateInfo.customer_contact" type="text" field_name="Kontaktperson" rules="required" />
          </div>
          <div class="w-full md:w-1/3 px-2 py-2">
            <BaseInput v-model="updateInfo.customer_reference" type="text" field_name="Ref" rules="required" />
          </div>
        </div>

        <div class="mx-2 border-b border-secondary-400 my-5"></div>
        <div class="flex flex-wrap">
          <div class="w-full text-sm font-bold italic px-2 mb-4">
            {{ $t("agreement_update_modal.lessor") }}
          </div>
          <div class="w-full md:w-1/3 px-2 py-2">
            <BaseInput v-model="updateInfo.dealer_name" type="text" :field_name="$t('agreement_update_modal.name')" readonly disabled />
          </div>
          <div class="w-full md:w-1/3 px-2 py-2">
            <BaseInput v-model="updateInfo.dealer_company_number" type="text" :field_name="$t('agreement_update_modal.org_number')" readonly disabled />
          </div>
          <div class="w-full md:w-1/3 px-2 py-2">
            <BaseInput v-model="updateInfo.dealer_phone" type="text" :field_name="$t('agreement_update_modal.phone')" readonly disabled />
          </div>
        </div>
        <div class="flex flex-wrap">
          <div class="w-full md:w-1/3 px-2 py-2">
            <BaseInput v-model="updateInfo.dealer_address" type="text" :field_name="$t('agreement_update_modal.address')" readonly disabled />
          </div>
          <div class="w-full md:w-1/3 px-2 py-2">
            <BaseInput v-model="updateInfo.dealer_zip_code" type="text" :field_name="$t('agreement_update_modal.zip_code')" readonly disabled />
          </div>
          <div class="w-full md:w-1/3 px-2 py-2">
            <BaseInput v-model="updateInfo.dealer_city" type="text" :field_name="$t('agreement_update_modal.ort')" readonly disabled />
          </div>
        </div>

        <div class="mx-2 border-b border-secondary-400 my-5"></div>
        <div class="flex flex-wrap">
          <div class="w-full md:w-1/3 px-2 py-2">
            <BaseSelect v-model="updateInfo.seller" :options="sellers" nameSelector="name" optionSelector="_id" :field_name="$t('agreement_update_modal.seller')" rules="required" />
          </div>
        </div>

        <div class="mx-2 border-b border-secondary-400 my-5"></div>
        <div class="flex flex-wrap">
          <div class="w-full md:w-1/2 px-2 py-2">
            <BaseTextarea rows="5" v-model="updateInfo.units_specification" :field_name="$t('agreement_update_modal.object_specification')" rules="required" />
          </div>
        </div>

        <div class="mx-2 border-b border-secondary-400 my-5"></div>
        <div class="flex flex-wrap">
          <div class="w-full md:w-1/3 px-2 py-2">
            <ValidationProvider name="Avtalsstart" rules="required" v-slot="{ classes, errors }">
              <div class="input-validate" :class="classes">
                <label class="text-gray-600 text-sm font-bold pb-2 font-serif">{{ $t("agreement_update_modal.contract_start") }}<b class="text-red-500 ml-1">*</b></label>
                <date-picker :lang="date_picker_lang" :key="date_picker_langKey" class="mt-2" v-model="updateInfo.contract_start" value-type="format" format="YYYY-MM-DD"></date-picker>
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </div>
          <div class="w-full md:w-1/3 px-2 py-2">
            <BaseInput v-model="updateInfo.contract_period" type="text" field_name="Avtalsperiod" rules="required" />
          </div>
          <div class="w-full md:w-1/3 px-2 py-2">
            <BaseSelect v-model="updateInfo.sim_card" :options="sim_operators" field_name="Simkort" rules="required" />
          </div>
          <div class="w-full md:w-1/3 px-2 py-2">
            <BaseSelect v-model="updateInfo.alarm_center" :options="[...alarm_centers, { _id: '-', name: 'Ingen' }]" nameSelector="name" optionSelector="_id" field_name="Larmcentral" rules="required" />
          </div>
        </div>

        <div class="mx-2 border-b border-secondary-400 my-5"></div>
        <div class="flex flex-wrap">
          <div class="w-full md:w-1/3 px-2 py-2">
            <BaseInput v-model="updateInfo.payment_terms" type="text" field_name="Betalningsvillkor" rules="required" />
          </div>
          <div class="w-full md:w-1/3 px-2 py-2">
            <BaseInput v-model="updateInfo.setup_cost" type="text" field_name="Uppläggningsavgift" rules="required" />
          </div>
          <div class="w-full md:w-1/3 px-2 py-2">
            <BaseInput v-model="updateInfo.rental_month_cost" type="text" field_name="Hyreskostnad" rules="required" />
          </div>
        </div>

        <div class="mx-2 border-b border-secondary-400 my-5"></div>
        <div class="flex flex-wrap">
          <div class="w-full md:w-1/2 px-2 py-2">
            <BaseTextarea rows="5" v-model="updateInfo.special_terms" field_name="Särskilda villkor" rules="required" />
          </div>
        </div>

        <div class="mx-2 border-b border-secondary-400 my-5"></div>
        <div class="flex flex-wrap">
          <div class="w-full md:w-1/2 px-2 py-2">
            <label class="text-gray-600 text-sm font-bold pb-2 font-serif"
              ><span v-if="updateInfo.pdf_file != null">{{ $t("agreement_update_modal.change") }} </span>{{ $t("agreement_update_modal.signed_agreement") }}</label
            >
            <input type="file" accept="application/pdf" ref="pdfCreate" @change="fileChangeCreate()" class="bg-gray-100 rounded w-full text-sm text-gray-900 focus:outline-none border-b-4 border-gray-300 transition duration-500 px-3 pt-2 pb-2" />
          </div>
          <div class="w-full md:w-1/2 px-2 py-2" v-if="updateInfo.pdf_file && !remove_pdf_file">
            <button class="btn-red-outline py-2 mt-7" @click.prevent="removePDF()">{{ $t("agreement_update_modal.delete_pdf") }}<BaseIcon icon="file-pdf" class="ml-1" /></button>
          </div>
        </div>

        <!-- <div class="mx-2 border-b border-secondary-400 my-5"></div>
        <div class="flex flex-wrap">
          <div class="w-full text-sm font-bold italic px-2">
            PDF-inställning
          </div>
        </div>
        <div class="flex flex-wrap">
          <div class="w-full md:w-1/2 px-2 py-2">
            <label class="inline-flex items-center mt-3">
                <input v-model="updateInfo.pdf_hide_alarmcenter" :value="updateInfo.pdf_hide_alarmcenter" type="checkbox" class="form-checkbox h-4 w-4">
                <span class="ml-2 text-sm font-sans font-semibold">Dölj larmcentral</span>
              </label>
          </div>
        </div> -->
      </ValidationObserver>
    </div>
    <div class="modal-footer">
      <div class="footer-container clearfix">
        <button v-if="updateInfo && updateInfo.pdf_file == null" class="btn-blue-outline px-3 py-2 float-left" @click="downloadPDF(pdf_agreement)">{{ $t("agreement_update_modal.create_pdf") }}<BaseIcon icon="file-pdf" class="ml-1" /></button>
        <button v-else class="btn-red-outline px-3 py-2 float-left" @click="hide('update-agreement')">{{ $t("user_actions.cancel") }}<BaseIcon icon="times-circle" class="ml-1" /></button>
        <button class="btn-green px-3 py-2 float-right" @click="updateAgreement()">{{ $t("user_actions.save") }}<BaseIcon icon="save" class="ml-1" /></button>
      </div>
    </div>
  </modal>
</template>

<script>
const MODAL_WIDTH = 900;

import DatePicker from "vue2-datepicker";
import "vue2-datepicker/locale/sv";
import "vue2-datepicker/locale/pl";
import "vue2-datepicker/index.css";

export default {
  props: {
    agreementInfo: {
      required: true,
      default: null,
    },
  },
  components: {
    DatePicker,
  },
  data() {
    return {
      user: this.$store.state.user,
      agreement_types: [
        { id: "customer", name: "Kunder" },
        { id: "reseller", name: "Återförsäljare" },
      ],
      sim_operators: ["Telia", "Telia DCP", "Telenor", "Tele2"],
      sellers: [],
      alarm_centers: [],
      updateInfo: null,
      pdf_agreement: null,
      selected_pdf_file: null,
      remove_pdf_file: false,
      date_picker_lang: this.$i18n.locale,
      date_picker_langKey: 0,
    };
  },
  watch: {
    user() {
      return this.$store.state.user;
    },
    agreementInfo(newVal) {
      // eslint-disable-line
      this.updateInfo = _.cloneDeep(newVal);
      this.pdf_agreement = _.cloneDeep(newVal);
      this.selected_pdf_file = null;
      this.remove_pdf_file = false;
    },
    "$i18n.locale"(newLocale) {
      this.date_picker_lang = newLocale;
      this.date_picker_langKey++;
    },
  },
  methods: {
    getSellers() {
      this.axios
        .get(`${process.env.VUE_APP_SERVER_URL}/sellers/`)
        .then((response) => {
          this.sellers = response.data.data;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    getAlarmCenters() {
      this.axios
        .get(`${process.env.VUE_APP_SERVER_URL}/alarm-central`)
        .then((response) => {
          this.alarm_centers = response.data.data;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    updateAgreement() {
      this.$refs.formUpdateAgreement.validate().then((success) => {
        if (!success) {
          return;
        }

        let headers = {
          "Content-Type": "multipart/form-data",
          "X-Access-Token": this.$store.state.token,
        };

        let formData = new FormData();

        formData.append("e_bill", this.updateInfo.e_bill);
        formData.append("customer_name", this.updateInfo.customer_name);
        formData.append("company_number", this.updateInfo.company_number);
        formData.append("customer_phone", this.updateInfo.customer_phone);
        formData.append("billing_adress", this.updateInfo.billing_adress);
        formData.append("billing_zip_code", this.updateInfo.billing_zip_code);
        formData.append("billing_city", this.updateInfo.billing_city);
        formData.append("customer_contact", this.updateInfo.customer_contact);
        formData.append("customer_reference", this.updateInfo.customer_reference);
        formData.append("dealer_name", this.updateInfo.dealer_name);
        formData.append("dealer_company_number", this.updateInfo.dealer_company_number);
        formData.append("dealer_phone", this.updateInfo.dealer_phone);
        formData.append("dealer_address", this.updateInfo.dealer_address);
        formData.append("dealer_zip_code", this.updateInfo.dealer_zip_code);
        formData.append("dealer_city", this.updateInfo.dealer_city);
        formData.append("seller", this.updateInfo.seller);
        formData.append("units_specification", this.updateInfo.units_specification);
        formData.append("contract_start", this.updateInfo.contract_start);
        formData.append("contract_period", this.updateInfo.contract_period);
        formData.append("setup_cost", this.updateInfo.setup_cost);
        formData.append("rental_month_cost", this.updateInfo.rental_month_cost);
        formData.append("payment_terms", this.updateInfo.payment_terms);
        formData.append("sim_card", this.updateInfo.sim_card);
        formData.append("alarm_center", this.updateInfo.alarm_center);
        formData.append("special_terms", this.updateInfo.special_terms);
        // formData.append('pdf_hide_alarmcenter', this.updateInfo.pdf_hide_alarmcenter);
        if (this.selected_pdf_file) formData.append("pdf", this.selected_pdf_file);
        if (this.remove_pdf_file) formData.append("remove_pdf_file", this.remove_pdf_file);

        this.axios
          .put(`${process.env.VUE_APP_SERVER_URL}/agreements/${this.updateInfo._id}`, formData, headers)
          .then(() => {
            // eslint-disable-line
            this.$emit("updateAgreement");
            this.$modal.hide("update-agreement");
          })
          .catch((error) => {
            this.handleError(error);
          });
      });
    },

    fileChangeCreate() {
      if (this.$refs.pdfCreate.files.length > 0) this.selected_pdf_file = this.$refs.pdfCreate.files[0];
      else this.selected_pdf_file = null;
    },

    downloadPDF(agreement) {
      let doc = this.generateAgreementPDF(agreement);
      doc.save(`${agreement.agreement_number}-${agreement.customer_name}.pdf`);
    },

    removePDF() {
      this.updateInfo.pdf_file = null;
      this.remove_pdf_file = true;
    },

    getSellerName(id) {
      let seller = _.find(this.sellers, { _id: id });
      if (seller) return seller.name;
      else return "";
    },

    hide(id) {
      this.$refs.formUpdateAgreement.reset();
      this.$modal.hide(id);
    },
  },

  created() {
    this.modalWidth = window.innerWidth < MODAL_WIDTH ? window.innerWidth : MODAL_WIDTH;
    if (this.user && /(^admin$)|(^seller$)/.test(this.user.role)) {
      this.getSellers();
      this.getAlarmCenters();
    }
  },
};
</script>
