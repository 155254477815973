<template>
  <modal name="agreement-alarm" class="modal-inner" transition="pop-out" :width="modalWidth" :focus-trap="true" :min-height="400" height="auto" :scrollable="true" :resizable="true" :adaptive="true" :reset="true">
    <span class="close-button" @click="hide('agreement-alarm')"><BaseIcon icon="times-circle" class="text-white"/></span>
    <div class="modal-header text-lg bg-primary-50 font-serif">
      {{ $t("my_agreement.agreement_alarms") }}<span v-if="agreementNumber"> - {{ agreementNumber }}</span>
    </div>
    <div class="modal-body less-bottom-padding">
      <div v-if="isLoading" class="border border-gray-200 rounded-lg mt-3 py-3 px-3 font-sm text-gray-500 text-center">
        <BaseIcon icon="spinner" spin />
      </div>
      <div v-if="customers && customers.length > 0">
        <div class="flex">
          <div class="w-full clearfix">
            <div class="float-right flex flex-wrap">
              <button class="btn-secondary-outline mt-3 sm:mt-0" @click.prevent="expandUnits()">
                <span v-if="!all_expanded">
                  {{ $t("expand") }}
                  <BaseIcon icon="plus-circle" class="ml-1" />
                </span>
                <span v-else>
                  {{ $t("minimize") }}
                  <BaseIcon icon="minus-circle" class="ml-1" />
                </span>
              </button>
            </div>
          </div>
        </div>
        <div class="grid border border-gray-200 rounded-lg mt-3 py-3 px-3" v-for="customer in customers" :key="customer._id">
          <div class="text-md font-serif font-semibold text-primary-500 mb-2">
            {{ customer.name }}
          </div>
          <div class="grid mt-1" v-for="(unit, index) in customer.units" :key="index">
            <div
              class="clearfix cursor-pointer"
              @click.prevent="
                unit.isAlarmVisible = !unit.isAlarmVisible;
                checkExpandUnit();
              "
            >
              <div class="float-left text-sm font-semibold">
                {{ unit.name }}
                <span class="text-xs text-gray-400"> ( {{ unit.alarms.length }} {{ $t("my_agreement.alarm") }} )</span>
              </div>
              <div class="float-right" v-if="unit.alarms.length > 0">
                <BaseIcon v-if="!unit.isAlarmVisible" class="text-sm text-primary-500" icon="plus-circle" />
                <BaseIcon v-if="unit.isAlarmVisible" class="text-sm text-primary-500" icon="minus-circle" />
              </div>
            </div>
            <div class="mb-1" v-show="unit.isAlarmVisible">
              <div class="grid" v-for="(alarm, c) in unit.alarms" :key="c">
                <div class="pb-1 clearfix">
                  <div class="float-left">
                    <span class="text-xs font-medium"> {{ alarm.unit_name }} - {{ alarm.modelnumber }} </span>
                  </div>
                  <div class="float-right">
                    <span class="text-xs font-medium">
                      {{ formatDate(alarm.contract_start) }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="customers && customers.length == 0" class="border border-gray-200 rounded-lg mt-3 py-3 px-3 font-sm text-gray-500 text-center">
        {{ $t("my_agreement.no_alarm_found") }}
      </div>
    </div>
  </modal>
</template>

<script>
const MODAL_WIDTH = 800;

export default {
  props: {
    agreementNumber: {
      required: true,
      default: null,
    },
  },

  data() {
    return {
      isLoading: false,
      all_expanded: false,
      customers: null,
    };
  },

  watch: {
    agreementNumber(newVal) {
      if (newVal != null) {
        this.customers = null;
        this.all_expanded = false;
        this.getAgreementAlarms();
      }
    },
  },

  computed: {
    user() {
      return this.$store.state.user;
    },
  },

  methods: {
    getAgreementAlarms() {
      this.isLoading = true;

      this.axios
        .get(`${process.env.VUE_APP_SERVER_URL}/agreement/alarms/${this.agreementNumber}`)
        .then((response) => {
          this.customers = response.data.customers;
          this.isLoading = false;
        })
        .catch((error) => {
          this.handleError(error);
          this.isLoading = false;
        });
    },

    expandUnits() {
      this.all_expanded = !this.all_expanded;
      this.customers.forEach((customer) => {
        customer.units.forEach((unit) => {
          if (this.all_expanded) unit.isAlarmVisible = true;
          else unit.isAlarmVisible = false;
        });
      });
    },

    checkExpandUnit() {
      let all_expanded = false;
      this.customers.forEach((customer) => {
        customer.units.forEach((unit) => {
          if (unit.isAlarmVisible) all_expanded = true;
        });
      });

      if (all_expanded) this.all_expanded = true;
      else this.all_expanded = false;
    },

    formatDate(date) {
      return this.moment(date).format("YYYY-MM-DD");
    },

    hide(id) {
      this.$modal.hide(id);
    },
  },

  created() {
    this.modalWidth = window.innerWidth < MODAL_WIDTH ? window.innerWidth : MODAL_WIDTH;
  },
};
</script>
